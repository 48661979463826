export const ar = {
  caption: {
    regions: 'المناطق',
    region_details: 'تفاصيل المنطقة المحيطة',
    governorates: 'المناطق الإدارية',
    governorate_details: 'تحافظ على التفاصيل',
    select_region: 'حدد المنطقة المحيطة',
    add: 'يضيف',
    edit: 'يحرر',
    delete: 'يمسح',
    edit_governorate: 'تحرير المحافظة',
    add_new_governorate: 'أضف المحافظة',
    add_new_region: 'أضف المنطقة المحيطة',
    edit_region: 'تحرير المنطقة المحيطة',
  },
  form: {
    region_name: {
      label: 'اسم المنطقة المحيطة',
      required: 'اسم المنطقة المحيطة مطلوب',
    },
    governorate_name: {
      label: 'اسم المحافظة',
      required: 'اسم المحافظة مطلوب',
    },
    latitude: {
      label: 'خط العرض',
      placeholder: 'على سبيل المثال: 23.885942',
      required: 'خط العرض مطلوب',
      max_float: 'يجب أن يكون المبلغ رقمًا عشريًا بحد أقصى سبعة أرقام بعد الفاصلة',
      min: 'يجب أن يكون خط العرض -90 على الأقل',
      max: 'يجب أن يكون خط العرض 90 على الأقل',
      type_error: 'يجب أن يكون خط العرض رقمًا صالحًا',
    },
    longitude: {
      label: 'خط الطول',
      placeholder: 'على سبيل المثال: 45.0791621',
      required: 'خط الطول مطلوب',
      max_float: 'يجب أن يكون المبلغ رقمًا عشريًا بحد أقصى سبعة أرقام بعد الفاصلة',
      min: 'يجب أن يكون خط الطول -180 على الأقل',
      max: 'يجب أن يكون خط الطول 180 على الأقل',
      type_error: 'يجب أن يكون خط الطول رقمًا صالحًا',
    },
  },
};
