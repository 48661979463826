export const ar = {
  ticket: {
    ticket_created: 'تم إنشاء تذكرة جديدة',
    new_message: 'رسالة تذكرة جديدة',
    ticket_closed: 'تذكرة مغلقة',
    ticket_forwarded: 'تذكرة جديدة معروفة',
    new_applied_request_message: 'رسالة طلب تطبيقي جديد',
  },
  donation: {
    donation_received: 'تبرع جديد تبرع',
  },
  apply_request: {
    new_message: 'رسالة جديدة على طلب تقديم للبائع',
  },
};
