export const en = {
  campaign: {
    cant_delete: 'Cant delete this campaign!, either it is not rejected yet / it has been donated!',
  },
  evaluator: {
    cant_delete: `Can't delete evaluator because it has applied requests`,
  },
  transactions: {
    delete_donor_has_donations: `Can't delete donor, because it has donations history`,
  },
};
