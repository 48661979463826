export const ar = {
  button: {
    view: 'عرض',
    delete_email: 'حذف ايميل',
    edit_email: 'تعديل ايميل',
  },

  headers: {
    sender_email: 'ايميل المرسل',
    name: 'الاسم',
    sent_at: 'تاريخ الارسال',
  },
  title: {
    all_emails: 'كل الايميلات',
  },
  form: {
    email: {
      label: 'الالبريد الإلكتروني الالكتروني',
    },
    senderEmail: {
      label: 'ايميل المرسل',
    },
    name: {
      label: 'الاسم',
    },
    location: {
      label: 'الموقع',
    },
    how_did_you_find: {
      label: 'كيف وصلت الينا',
    },
    suggestion: {
      label: 'الاقتراحت',
    },
    upload: {
      label: 'الرجاء تحميل اي ملفات تساعدنا على فهم رغباتك',
    },
    buttons: {
      submit: 'إرسال النموذج',
    },
  },
};
