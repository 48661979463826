export const ar = {
  login: {
    title: 'تسجيل الدخول في مساجدنا',
    form: {
      email: {
        label: 'العنوان الالبريد الإلكتروني الإلكتروني',
        required: 'الالبريد الإلكتروني الالكتروني مطلوب',
        valid:
          'يجب أن يكون الالبريد الإلكتروني الإلكتروني العنوان البريد الإلكتروني إلكتروني صالحًا',
      },
      password: {
        label: 'كلمة المرور',
        required: 'كلمة المرور مطلوبة',
      },
    },
  },
  button: {
    back: 'خلف',
    login: 'تسجيل الدخول',
  },
};
