export const en = {
  ticket: {
    ticket_created: 'New Ticket Created',
    new_message: 'New Ticket message',
    ticket_closed: 'Ticket Closed',
    ticket_forwarded: 'New Forwarded Ticket',
    new_applied_request_message: 'New Applied Request Message',
  },
  donation: {
    donation_received: 'New Donation Received',
  },
  apply_request: {
    new_message: 'New Vendor Applied Request Message',
  },
};
