export const en = {
  campaign: {
    edit_request: {
      have_milestone_process: 'You have milestone process for your deleted milestone!',
      have_milestone_invoice: 'You have milestone invoice for your deleted milestone!',
    },
  },
  regions: {
    latitude_exist: 'Latitude cordinate is using for another place!',
    longitude_exist: 'Longitude cordinate is using for another place!',
  },
  operator: {
    cant_delete_project_in_progress: `Operator has existing project progress`,
    cant_delete_campaign_in_progress: `Operator has existing campaign progress`,
  },
  donor: {
    email_exist: 'Email already exists',
  },
};
